@use '../abstracts/' as *;

.GalleryWrapper {
    background-color: transparent;
    min-height: 100vh;
    max-height: fit-content;
    width: 100vw;
    overflow: hidden;

    .Gallery {
        @include Flex(Row, Both);
        flex-wrap: wrap;
        width: 100%;
        gap: 1.5rem;
        padding: 7vh 3vw;

        img {
            user-select: none;
            background-size: cover;

            @include HoverScale();

            height: clamp(13rem, 30vw, 22rem);
        }
    }

    .Text {
        padding: 5vh 7vw;

        h4 {
            text-indent: 7vw;

            @include whenBig() {
                line-height: 3.5rem;
            }
        }
    }

    //Filters
    .Filters {
        @include Flex(Row, Both);
        gap: 3vw;
        color: white;
        padding: 7vh 1vw 0 1vw;
        flex-wrap: wrap;

        h4 {
            cursor: pointer;
            transition: $transition;
        }
    }

    .SelectedFilter {
        color: $lightred;
    }
}