@use '../abstracts' as *;

.aboutMe {
    @include Fullscreen();

    @include whenBig() {
        @include Flex("Row", "Both");
    }

    @include whenSmall() {
        @include Flex("Col", "Both");
    }

    padding-left: 5rem;
    padding-right: 5rem;

    img {
        height: clamp(15rem, 40vw, 30rem);
    }

    h4 {
        @include whenBig() {
            line-height: 3.5rem;
        }

        text-indent: 3rem;
    }
}