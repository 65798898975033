@use '../abstracts' as *;

.footer {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    gap: 10vw;
    min-width: 100vw;
    height: fit-content;
    padding: 3rem 0;

    img {
        width: clamp(3rem, 14vw, 9rem);
        user-select: none;
        background-size: cover;

        @include HoverScale();
    }
}