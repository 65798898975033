*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font: inherit;
}

html {
    overflow-y: overlay;
    overflow-x: hidden;
    scroll-snap-type: y proximity;
}

html,
body {
    scroll-behavior: smooth;
    background-color: transparent;
    margin: 0px;
    padding: 0px;
    width: 100vw;
    height: 100vh
}