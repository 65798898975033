@use '../abstracts' as *;

.homePage {
    @include Fullscreen();
    @include Flex(Row, Center);
    gap: 5rem;

    @include whenSmall() {
        @include Fullscreen();
        @include Flex("Col", "Center");
        padding: 3rem 0;
    }

    img {
        height: clamp(7rem, 25vw, 14rem);
        @include HoverScale();
    }
}