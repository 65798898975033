@use '../abstracts' as *;

.header {
    .pcNav {
        display: flex;
        justify-content: space-between;
        gap: 5rem;
        align-items: center;
        min-width: 100vw;
        padding: 2rem 5rem 0 5rem;

        .nav {
            @include Flex("Row", "Vertical");
            gap: 5rem;
        }

        img {
            user-select: none;
            background-size: cover;
            @include HoverScale();
            height: clamp(5rem, 20vw, 10rem);
        }
    }

    .mobileNav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        min-width: 100vw;
        padding: 1rem 1rem 0 0;

        img {
            user-select: none;
            background-size: cover;
            @include HoverScale();
            height: clamp(5rem, 20vw, 10rem);
        }

        .openIcon {
            transform: rotate(75deg);
        }

        .dropDownNav {
            padding: 2rem 0;
            position: fixed;
            right: 0;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            @include Fullscreen;
            gap: 2rem;
            @include Flex(Col, Center);
            justify-content: space-evenly;
            z-index: 999;
            background-color: rgba(0, 0, 0, 0.5);
            backdrop-filter: blur(6px);
            overflow: auto;

            img {
                height: clamp(14rem, 25vw, 24rem);
            }

            // .closeIcon {
            //     padding: 1.5rem;
            //     position: fixed;
            //     top: 0;
            //     right: 0;
            // }
        }
    }
}