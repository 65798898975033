@use '../abstracts/' as *;

.Modal {
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    @include Fullscreen;
    @include Flex(Row, Both);
    z-index: 999;
    background-color: rgba(0, 0, 0, .2);
    backdrop-filter: blur(5px);

    &.Content {
        z-index: 999;
        position: relative;

        img {
            user-select: none;
            max-height: 50vh;
            max-width: 85vw;
        }

        &.Single {
            @include Flex(Col, Both);
        }

        &.Multi {
            @include Flex(Col, Horizontal);
            justify-content: flex-start;
            padding: 20vh 0;
            gap: 5rem;
            overflow: auto;
            height: 100%;
            scrollbar-width: none;
        }
    }

}

// .Arrow {
//     position: absolute;
//     @include Flex(Row, Both);
//     max-height: fit-content;
//     bottom: 0;
//     width: 100vw;
//     z-index: 1000;
//     background: rgb(0, 0, 0);
//     background: linear-gradient(0deg, rgba(0, 0, 0, 1) 10%, rgba(94, 94, 94, 0) 100%);

//     &.Up {
//         transform: rotate(180deg);
//         // background: linear-gradient(180deg, rgba(0, 0, 0, 1) 10%, rgba(94, 94, 94, 0) 100%);
//         top: 0;
//     }

//     &.Down {
//         bottom: 0;
//     }
// }