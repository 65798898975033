@use '../abstracts' as *;

body,
html {
    font-family: "Blue";
    background-image: url('../../assets/images/blackbg.png');
    color: white;
    overflow-y: overlay;
    box-sizing: border-box;
    position: relative;
}


.app-root {
    @include Fullscreen();
    @include Flex("Col", "Horizontal");
}

.centered {
    @include Fullscreen();
    @include Flex("Col", "Both");
}

.col-centered {
    @include Flex("Col", "Center");
}

.row-centered {
    @include Flex("Row", "Center");
}

.hideWhenBig {
    @include whenBig() {
        display: none !important;
        visibility: hidden !important;
    }
}

.hideWhenSmall {
    @include whenSmall() {
        display: none !important;
        visibility: hidden !important;
    }
}

//Custom Scrollbar
::-webkit-scrollbar {
    width: 20px;
    position: fixed;
    display: block;
}

::-webkit-scrollbar-track {
    background-color: transparent;
}

*::-webkit-scrollbar-track-piece {
    background-color: transparent;
}

*::-webkit-scrollbar-button {
    display: none;
}

::-webkit-scrollbar-thumb {
    background-color: rgba(157, 157, 157, 0.4);
    border-radius: 20px;
    border: 7px solid transparent;
    background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
    background-color: rgba(157, 157, 157, 0.6);
}

//Text sizes
h1 {
    font-size: clamp(3rem, 13vw, 20rem);
}

h2 {
    font-size: clamp(3rem, 10vw, 10rem);
}

h3 {
    font-size: clamp(2rem, 3vw, 5rem);
}

h4 {
    font-size: clamp(1rem, 3vw, 2.5rem)
}

h5 {
    font-size: clamp(.7rem, 1.5vw, 2rem);
}

h6 {
    font-size: clamp(.5rem, 1.5vw, 1rem);
}